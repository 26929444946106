// src/pages/registration/RegistrationContent.js

import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Disclosure, Transition } from "@headlessui/react";

import { useCarerTypeState } from "../../context/CarerTypeContext";
import { EventFilterDataContext } from "../../context/EventFilterDataContext";
import RegistrationRadio from "../../components/RadioButtons/RegistrationRadio";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/20/solid";

import Checkboxes from "../../components/Checkboxes/Checkboxes";
import axiosInstance from "../../lib/axiosInstance";
import { endPoint } from "../../constants/endPoints";
import { manageServerMessage } from "../../utils/messageUtils";

import ApplicationDisclaimerModal from "../../components/Modals/ApplicationDisclaimerModal";
import { useNavigate } from "react-router-dom";

const agesData = [
  "19 或以下",
  "20-29",
  "30-39",
  "40-49",
  "50-59",
  "60-69",
  "70-79",
  "80 或以上",
];

const employmentStatusData = ["全職", "兼職", "退休", "學生", "無業", "待業"];
const industryData = [
  "製造業",
  "建造業",
  "批發、零售、進出口貿易、飲食及酒店業",
  "運輸、倉庫及通訊業",
  "金融、保險、地產及商用服務業",
  "社區、社會及個人服務業",
  "公務員",
];
const jobData = [
  "專業及輔助專業人士",
  "經理及行政人員",
  "文書支援人員",
  "服務工作人員",
  "技術人員",
];
const martialStatusData = ["單身", "已婚", "離婚/分居", "喪偶"];
const meTimeData = [
  "少於 7 小時",
  "約 7-14 小時",
  "約 15-21 小時",
  "約 22-28 小時",
  "約 29-35 小時",
];
const activitiesMeTimeData = [
  "做運動",
  "社交活動 (如：相約朋友聚會)",
  "處理私人事務 (如：處理銀行財務事宜)",
  "休閒娛樂 (如：看電視、看電影、玩手機、上興趣班)",
  "少睡片刻",
];
const howDidYouFindOutData = [
  "電郵",
  "Facebook 專頁",
  "Instagram",
  "職員介紹",
  "與照顧者相關的社區活動",
  "親友介紹",
  "宣傳單張或刊物",
  "搜尋引擎（例如 Google, Yahoo, Bing 等等）",
];
const preferredComChannelData = ["WhatsApp", "電郵", "電話"];

const ageOfDependant = [
  "0-5 歲",
  "6-12 歲",
  "13-19 歲",
  "20-29 歲",
  "30-39 歲",
  "40-49 歲",
  "50-59 歲",
  "60-69 歲",
  "70-79 歲",
  "歲或以上",
];

const ifCareRecipientData = [
  "父母親",
  "子女",
  "兄弟姐妹",
  "夫婦",
  "孫兒女",
  "朋友",
  "其他親屬關係",
];
const careMethodData = [
  "陪診",
  "起居飲食",
  "經濟支援",
  "餵藥",
  "孫兒女",
  "日常護理",
  "情緒支援",
];
const numOfCareHoursData = [
  "少於 28 小時",
  "28-40 小時",
  "41-50 小時",
  "51-60 小時",
  "61 小時或以上",
];
const aspectAffectsData = [
  "身心疲乏",
  "體力負擔",
  "睡眠質素",
  "經濟負擔",
  "社交生活",
  "精神困擾（例如：抑鬱、焦累、強迫症等等",
  "平衡照顧責任",
  "家庭生活角色",
  "居住環境",
  "私人空間/時間",
  "工作平衡",
];

const schema = yup.object().shape({
  personalInfo: yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
  }),
  personalInfoContact: yup.object().shape({
    phone: yup
      .string()
      .required("Phone number is required")
      .matches(/^(2|3|5|6|7|8|9)\d{7}$/, "Invalid phone number format"),
    email: yup.string().email("Invalid email format"),
    // Add more fields and validations as needed
  }),
  // Add more sections and validation schemas as needed
});

const RegistrationContent = ({ page, errorMessage, loading }) => {
  const { t } = useTranslation();

  const { eventFilterData, isLoadingEventFilterData, fetchEventFilterData } =
    useContext(EventFilterDataContext);
  const { carerTypeData } = useCarerTypeState();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [submitting, setSubmitting] = useState(false);
  const [serverMessage, setServerMessage] = useState({ message: "", type: "" });
  // Modal
  const [isOpen, setIsOpen] = useState(false);
  // State to keep track of selected carer types
  const [selectedCarerTypes, setSelectedCarerTypes] = useState([]);
  // form data
  const [data, setData] = useState(null);

  // Organizations from API
  const [localOrganizations, setLocalOrganizations] = useState([]);
  // State to keep track of the selected organization
  const [selectedOrganization, setSelectedOrganization] = useState();
  // 07 selected age group
  const [selectedAge, setSelectedAge] = useState();

  // 10 selected employment status
  const [selectedEmployment, setSelectedEmployment] = useState();
  // 11 selected industry
  const [selectedIndustry, setSelectedIndustry] = useState();
  // 12 selected job
  const [selectedJob, setSelectedJob] = useState();
  // 13 selected martial
  const [selectedMartial, setSelectedMartial] = useState();
  // 15 Selected me time
  const [selectedMeTime, setSelectedMeTime] = useState();
  // 16 Selected me time activities
  const [selectedMeTimeActivities, setSelectedMeTimeActivities] = useState();
  // 19 Selected how did you hear about us
  const [selectedHearAboutUs, setSelectedHearAboutUs] = useState();
  // 20 Selected preferec communication channel
  const [selectedPreferredCom, setSelectedPreferredCom] = useState();

  useEffect(() => {
    if (
      !isLoadingEventFilterData &&
      eventFilterData &&
      eventFilterData.organizations &&
      eventFilterData.organizations.length > 0
    ) {
      // Create a copy of eventFilterData.organizations without the first element
      const organizationsWithoutFirst = eventFilterData.organizations.slice(1);
      // Set the localOrganizations to the modified array
      setLocalOrganizations(organizationsWithoutFirst);
    }
    // eslint-disable-next-line
  }, [eventFilterData]);

  // Auto select the first org radio when localOrganizations is updated
  useEffect(() => {
    if (
      !isLoadingEventFilterData &&
      eventFilterData &&
      eventFilterData.organizations &&
      eventFilterData.organizations.length > 0 &&
      localOrganizations &&
      localOrganizations.length > 0
    ) {
      setSelectedOrganization(localOrganizations[0].id);
    }

    // eslint-disable-next-line
  }, [localOrganizations]);

  // Handle carer type checkbox change
  const handleCarerTypeChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedCarerTypes([...selectedCarerTypes, value]);
    } else {
      setSelectedCarerTypes(
        selectedCarerTypes.filter((type) => type !== value)
      );
    }
  };

  // Function to handle radio button selection
  const handleOrganizationChange = (e) => {
    setSelectedOrganization(e.target.value);
  };

  const onSubmit = (formData) => {
    // Validate if at least one carer type is selected
    if (selectedCarerTypes.length === 0) {
      alert("Please select at least one carer type");
      return;
    }
    setData(formData);
    setIsOpen(true);
  };

  const confirmSubmit = async () => {
    // console.log(data);

    setSubmitting(true);
    try {
      // console.log(data);
      // Combine form data into one JSON object with sections
      // const formData = {
      //   // 01 - Is Caregiver
      //   isCaregiver: {
      //     isCaregiver: data.isCaregiver.isCaregiverOption,
      //   },
      //   // 02 - Personal Info Name
      //   personalInfoName: {
      //     firstName: data.personalInfo.firstName,
      //     lastName: data.personalInfo.lastName,
      //     englishName: data.personalInfo.englishName,
      //   },
      //   // 03 - Personal Info name
      //   personalInfoContact: {
      //     phone: data.personalInfoContact.phone,
      //     email: data.personalInfoContact.email,
      //   },
      //   // 04 - Gender
      //   gender: {
      //     gender: data.gender.genderOption,
      //   },
      //   // 05 Carer Type
      //   carerType: {
      //     selectedCarerTypes: selectedCarerTypes,
      //     carerTypeMoreDetails: data.carerType.ctMoreDetails,
      //   },
      //   // 06 Card issuing organization
      //   issuingOrganization: {
      //     organization: selectedOrganization,
      //   },
      //   // 07 Age group
      //   age: {
      //     age: selectedAge,
      //   },
      //   // 08 Address group
      //   // address: {
      //   //   flat: data.address.flat,
      //   //   floor: data.address.floor,
      //   //   building: data.address.building,
      //   //   district: data.address.district,
      //   // },
      //   // 09 Start Caregiver
      //   // startCaregiver: {
      //   //   dateStart: data.startCaregiver.dateStart,
      //   // },
      //   // 10 Employment status
      //   employmentStatus: {
      //     employmentStatus: selectedEmployment,
      //   },
      //   // 11 Industry
      //   industry: {
      //     industry: selectedIndustry,
      //   },
      //   // 12 Job
      //   job: {
      //     job: selectedJob,
      //   },
      //   // 13 Martial status
      //   mastialStatus: {
      //     mastialStatus: selectedMartial,
      //   },
      //   // 14 Challenges
      //   challenges: {
      //     challenges: data.challenges.challenges,
      //   },
      //   // 15 me time
      //   meTime: {
      //     meTime: selectedMeTime,
      //   },
      //   // 16 me time activities
      //   meTimeActivities: {
      //     meTimeActivities: selectedMeTimeActivities,
      //   },
      //   // 17 your skills
      //   skills: {
      //     skills: data.skills.skills,
      //   },
      //   // 19 how did you hear
      //   hearAboutUs: {
      //     hearAboutUs: selectedHearAboutUs,
      //   },
      //   // 20 your pref com
      //   preferredCom: {
      //     preferredCom: selectedPreferredCom,
      //   },
      // };
      // console.log(data);
      // Payload
      const payload = {
        isCarer: data.isCaregiver.isCaregiverOption,
        firstName: data.personalInfo.firstName,
        lastName: data.personalInfo.lastName,
        englishName: data.personalInfo.englishName || null,
        gender: data.gender.genderOption,
        phone: data.personalInfoContact.phone,

        organizationId: selectedOrganization,
        carerTypeIds: selectedCarerTypes.map((carerId) => ({ id: carerId })),
        content: [{ somecontent: "somecontent" }],
      };
      if (data.personalInfoContact.email !== "") {
        payload.email = data.personalInfoContact.email;
      }

      // console.log("Payload", payload);

      // // Send data to backend API
      // console.log("Form data:", formData);

      const response = await axiosInstance.post(endPoint.APPLICATION, payload);
      // Handle successful

      // // Set success message
      // manageServerMessage(
      //   setServerMessage,
      //   t("common:messages.success.create", { field: "Application" }),
      //   "success"
      // );

      // Redirect to the success page
      // Redirect to the success page with data
      navigate("/carereps-registration-form/success", {
        state: { data: response.data },
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      let errorMessage = t("errors:unexpectedError");
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage = error.response.data.message || t("errors:serverError");
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = t("errors:networkError");
      }

      manageServerMessage(setServerMessage, errorMessage, "error");
    } finally {
      setSubmitting(false);
    }
  };

  // Call fetchEventFilterData when the component mounts (one time)
  useEffect(() => {
    if (!isLoadingEventFilterData) {
      fetchEventFilterData();
    }
    // eslint-disable-next-line
  }, []);
  // Call fetchEventFilterData when the component mounts (one time)
  // useEffect(() => {
  //   console.log(errors);
  //   // eslint-disable-next-line
  // }, [errors]);

  // useEffect(() => {
  //   console.log("age value:", selectedAge);
  // }, [selectedAge]);
  // useEffect(() => {
  //   console.log("hear about us value:", selectedHearAboutUs);
  // }, [selectedHearAboutUs]);

  // useEffect(() => {
  //   console.log("ed", eventFilterData);
  // }, [eventFilterData]);

  return (
    <main className="justify-center">
      <ApplicationDisclaimerModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        confirmSubmit={confirmSubmit}
        serverMessage={serverMessage}
        submitting={submitting}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Header */}
        <div className="text-center justify-center flex flex-col gap-2 max-w-7xl mx-auto mt-12">
          <div className="uppercase text-sm font-bold text-slate-400">
            成為會員
          </div>
          <div className="leading-tight text-5xl font-bold text-black">
            <h1>參加者登記表格</h1>
          </div>
        </div>

        {/* Error notice */}
        {Object.keys(errors).length > 0 && (
          <div className="px-6 lg:px-4 xl:px-0 max-w-7xl mx-auto mt-12">
            <div
              className="text-left justify-center flex flex-col 
      bg-red-100/70 text-red-500 text-lg font-medium ring-1 ring-red-200/70 py-5 px-8 rounded-2xl">
              Please fill in the required fields
            </div>
          </div>
        )}

        {/* /// Section separator /// */}
        <div className="text-left bg-primary mt-12">
          <div className="leading-tight text-3xl max-w-7xl font-semibold text-white mx-auto py-4 px-8">
            <h2>被照顧者資料</h2>
          </div>
        </div>

        <span
          className="
       flex flex-col gap-2 max-w-7xl mx-auto mt-10 px-6 lg:px-4 xl:px-0
        font-medium text-2xl text-primary">
          必填欄位 *
        </span>

        {/* 01 */}
        <section className="text-center justify-center flex flex-col gap-2 max-w-7xl mx-auto mt-12 px-6 lg:px-4 xl:px-0">
          {/* Question */}
          <div className="flex flex-row items-center gap-5">
            <div className="uppercase text-base font-bold text-primary bg-primary/20 ring-1 ring-primary/30 px-4 rounded-full py-1.5">
              1
            </div>
            <div className="leading-tight text-2xl font-bold text-black">
              <h1>請問你是否一位照顧者？*</h1>
            </div>
          </div>
          {/* Answer */}
          <div className="flex flex-row px-6 md:px-16 space-x-14 mt-8">
            <div className="flex">
              <input
                id="isCaregiver1"
                type="radio"
                value={true}
                defaultChecked
                {...register("isCaregiver.isCaregiverOption")}
                className="h-6 w-6 border-gray-300 text-primary focus:ring-primary"
              />
              <label
                htmlFor="isCaregiver1"
                className="ml-4 block text-lg font-medium leading-6 text-gray-900">
                是
              </label>
            </div>
            <div className="flex">
              <input
                id="isCaregiver2"
                type="radio"
                value={false}
                {...register("isCaregiver.isCaregiverOption")}
                className="h-6 w-6 border-gray-300 text-primary focus:ring-primary"
              />
              <label
                htmlFor="isCaregiver2"
                className="ml-4 block text-lg font-medium leading-6 text-gray-900">
                否
              </label>
            </div>
          </div>

          <hr className="h-px bg-primary/20 border-0 my-11"></hr>
        </section>

        {/* 02 */}
        <section className="text-center justify-center flex flex-col gap-2 max-w-7xl mx-auto px-6 lg:px-4 xl:px-0">
          {/* Question */}
          <div className="flex flex-row items-center gap-5">
            <div className="uppercase text-base font-bold text-primary bg-primary/20 ring-1 ring-primary/30 px-4 rounded-full py-1.5">
              2
            </div>
            <div className="leading-tight text-2xl font-bold text-black">
              <h1>姓名*</h1>
            </div>
          </div>
          {/* Answer */}
          <div className="flex flex-col px-6 md:px-16 md:flex-row space-y-8 md:space-y-0 md:space-x-10 mt-8">
            {/* Last Name */}
            <div className="text-left">
              <label
                htmlFor="lastName"
                className="block text-base font-medium leading-6 text-slate-500">
                姓氏 （中）
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  {...register("personalInfo.lastName")}
                  className="block w-full rounded-xl border-0 py-2.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  placeholder={t("common:registrationForm.enterFamName")}
                />
              </div>
              {errors.personalInfo && errors.personalInfo.lastName && (
                <p className="text-sm text-red-500/90 mt-1">
                  {errors.personalInfo.lastName.message}
                </p>
              )}
            </div>
            {/* First Name */}
            <div className="text-left">
              <label
                htmlFor="firstName"
                className="block text-base font-medium leading-6 text-slate-500">
                名字 （中）
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  {...register("personalInfo.firstName")}
                  className="block w-full rounded-xl border-0 py-2.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  placeholder={t("common:registrationForm.enterFirstName")}
                />
              </div>
              {errors.personalInfo && errors.personalInfo.firstName && (
                <p className="text-sm text-red-500/90 mt-1">
                  {errors.personalInfo.firstName.message}
                </p>
              )}
            </div>
            {/* Engligh Name */}
            <div className="text-left">
              <label
                htmlFor="englishName"
                className="block text-base font-medium leading-6 text-slate-500">
                姓名（英）（可選填）
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="englishName"
                  name="englishName"
                  {...register("personalInfo.englishName")}
                  className="block w-full rounded-xl border-0 py-2.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  placeholder={t("common:registrationForm.enterEnglishName")}
                />
              </div>
              {errors.personalInfo && errors.personalInfo.english && (
                <p className="text-sm text-red-500/90 mt-1">
                  {errors.personalInfo.english.message}
                </p>
              )}
            </div>
          </div>

          <hr className="h-px bg-primary/20 border-0 my-11"></hr>
        </section>

        {/* 03 */}
        <section className="text-center justify-center flex flex-col gap-2 max-w-7xl mx-auto px-6 lg:px-4 xl:px-0">
          {/* Question */}
          <div className="flex flex-row items-center gap-5">
            <div className="uppercase text-base font-bold text-primary bg-primary/20 ring-1 ring-primary/30 px-4 rounded-full py-1.5">
              3
            </div>
            <div className="leading-tight text-2xl font-bold text-black">
              <h1>聯絡資訊*</h1>
            </div>
          </div>
          {/* Answer */}
          <div className="flex flex-col px-6 md:px-16 md:flex-row space-y-8 md:space-y-0 md:space-x-10 mt-8">
            {/* Last Name */}
            <div className="text-left">
              <label
                htmlFor="phone"
                className="block text-base font-medium leading-6 text-slate-500">
                聯絡電話
              </label>
              <div className="mt-2 flex rounded-xl shadow-sm">
                <span className="inline-flex items-center rounded-l-xl border border-r-0 border-gray-200 px-3 text-slate-400 sm:text-sm">
                  +852
                </span>
                <input
                  type="phone"
                  id="phone"
                  name="phone"
                  {...register("personalInfoContact.phone")}
                  className="block w-full min-w-0 flex-1 rounded-none rounded-r-xl py-2.5 px-4 text-gray-900 ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  placeholder="8888 8888"
                />
              </div>
              {errors.lastName && (
                <p className="text-sm text-red-500/90 mt-1">
                  {errors.lastName}
                </p>
              )}
            </div>
            {/* Email */}
            <div className="text-left">
              <label
                htmlFor="email"
                className="block text-base font-medium leading-6 text-slate-500">
                電郵（可選填）
              </label>
              <div className="mt-2">
                <input
                  type="email"
                  id="email"
                  name="email"
                  {...register("personalInfoContact.email")}
                  className="block w-full rounded-xl border-0 py-2.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  placeholder={t("common:registrationForm.enterEmail")}
                />
              </div>
              {errors.email && (
                <p className="text-sm text-red-500/90 mt-1">{errors.email}</p>
              )}
            </div>
          </div>
          <hr className="h-px bg-primary/20 border-0 my-11"></hr>
        </section>

        {/* 04 */}
        <section className="text-center justify-center flex flex-col gap-2 max-w-7xl mx-auto px-6 lg:px-4 xl:px-0">
          {/* Question */}
          <div className="flex flex-row items-center gap-5">
            <div className="uppercase text-base font-bold text-primary bg-primary/20 ring-1 ring-primary/30 px-4 rounded-full py-1.5">
              4
            </div>
            <div className="leading-tight text-2xl font-bold text-black">
              <h1>性別*</h1>
            </div>
          </div>
          {/* Answer */}
          <div className="flex flex-row px-6 md:px-16 space-x-14 mt-8">
            <div className="flex">
              <input
                id="gender1"
                type="radio"
                value="male"
                defaultChecked
                {...register("gender.genderOption")}
                className="h-6 w-6 border-gray-300 text-primary focus:ring-primary"
              />
              <label
                htmlFor="gender1"
                className="ml-4 block text-lg font-medium leading-6 text-gray-900">
                男
              </label>
            </div>
            <div className="flex">
              <input
                id="gender2"
                type="radio"
                value="female"
                {...register("gender.genderOption")}
                className="h-6 w-6 border-gray-300 text-primary focus:ring-primary"
              />
              <label
                htmlFor="gender2"
                className="ml-4 block text-lg font-medium leading-6 text-gray-900">
                女
              </label>
            </div>
          </div>

          <hr className="h-px bg-primary/20 border-0 my-11"></hr>
        </section>

        {/* 05 */}
        <section className="text-center justify-center flex flex-col gap-2 max-w-7xl mx-auto px-6 lg:px-4 xl:px-0">
          {/* Question */}
          <div className="flex flex-row items-center gap-5">
            <div className="uppercase text-base font-bold text-primary bg-primary/20 ring-1 ring-primary/30 px-4 rounded-full py-1.5">
              5
            </div>
            <div className="leading-tight text-left text-2xl font-bold text-black">
              <h1>照顧者類別：(如有需要，可剔選多於一項)*</h1>
            </div>
          </div>
          {/* Answer */}
          <div className="flex flex-col px-6 md:px-16 mt-8">
            <div className="flex flex-col mt-4 space-y-8 md:space-y-4">
              {carerTypeData &&
                carerTypeData.map((item, index) => (
                  <div
                    key={index}
                    className="flex md:items-center items-start text-left">
                    <input
                      id={`carerType-${item.id}`}
                      name="carerTypes"
                      type="checkbox"
                      value={item.id}
                      onChange={handleCarerTypeChange}
                      className="h-6 w-6 text-primary focus:ring-primary border-gray-300 rounded"
                    />
                    <label
                      htmlFor={`carerType-${item.id}`}
                      className="ml-4 text-lg text-gray-900 capitalize">
                      {item.name}
                    </label>
                  </div>
                ))}
            </div>

            {/*  Carer type more details */}
            <div className="text-left mt-12">
              <label
                htmlFor="ctMoreDetails"
                className="block leading-6 text-lg text-gray-900">
                <b>身體殘疾人士照顧者</b>{" "}
                如選取殘疾人士照顧者，請填寫被照顧者的徵狀:
              </label>

              <div className="mt-6 md:w-8/12">
                <textarea
                  type="text"
                  id="ctMoreDetails"
                  name="ctMoreDetails"
                  rows={8}
                  {...register("carerType.ctMoreDetails")}
                  className="block w-full rounded-xl text-base border-0 py-2.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:leading-6"
                  placeholder="請填寫被照顧者的徵狀"
                />
              </div>
              {errors.ctMoreDetails && (
                <p className="text-sm text-red-500/90 mt-1">
                  {errors.ctMoreDetails}
                </p>
              )}
            </div>
          </div>

          <hr className="h-px bg-primary/20 border-0 my-11"></hr>
        </section>

        {/* 06 */}
        <section className="text-center justify-center flex flex-col gap-2 max-w-7xl mx-auto px-6 lg:px-4 xl:px-0">
          {/* Question */}
          <div className="flex flex-row items-center gap-5">
            <div className="uppercase text-base font-bold text-primary bg-primary/20 ring-1 ring-primary/30 px-4 rounded-full py-1.5">
              6
            </div>
            <div className="leading-tight text-2xl font-bold text-black">
              <h1>發咭機構*</h1>
            </div>
          </div>
          {/* Answer */}
          <div className="flex flex-col px-6 md:px-16 mt-8">
            <div className="flex flex-col mt-4 space-y-4">
              {localOrganizations &&
                localOrganizations.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-start text-left">
                    <div className="flex items-center">
                      <input
                        id={`organization-${item.id}`}
                        name="organization"
                        type="radio"
                        value={item.id}
                        checked={selectedOrganization === item.id}
                        onChange={handleOrganizationChange}
                        className="h-6 w-6 text-primary focus:ring-primary border-gray-300"
                      />
                      <label
                        htmlFor={`organization-${item.id}`}
                        className="ml-4 text-lg text-gray-900 capitalize">
                        {item.name}
                      </label>
                    </div>
                    <label
                      aria-hidden
                      htmlFor={`organization-${item.id}`}
                      className="text-slate-600 md:px-10 py-2 lg:w-8/12">
                      {item.description}
                    </label>
                  </div>
                ))}
            </div>
          </div>

          <hr className="h-px bg-primary/20 border-0 my-11"></hr>
        </section>

        <div className="py-20 w-full justify-center text-center">
          <button
            type="submit"
            className="bg-primary hover:bg-primary-hover transition duration-300 rounded-full px-10 py-4 text-white ">
            {t("common:registrationForm.submit")}
          </button>
        </div>
      </form>
    </main>
  );
};

export default RegistrationContent;

// {/* 07 */}
// <section className="text-center justify-center flex flex-col gap-2 max-w-7xl mx-auto px-6 lg:px-4 xl:px-0">
//   <Disclosure defaultOpen={false} as="div">
//     {({ open }) => (
//       <Fragment>
//         {/* Question */}
//         <div className="flex flex-row items-center gap-5 pr-6">
//           <div className="flex flex-row items-center gap-5 flex-1">
//             <div className="uppercase text-base font-bold text-primary bg-primary/20 ring-1 ring-primary/30 px-4 rounded-full py-1.5">
//               7
//             </div>
//             <Disclosure.Button>
//               <div className="leading-tight text-2xl font-bold text-black cursor-pointer">
//                 <h1>年齡</h1>
//               </div>
//             </Disclosure.Button>
//           </div>
//           <Disclosure.Button>
//             {/* Foldable */}
//             <span className="flex h-7 items-center">
//               {open ? (
//                 <MinusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               ) : (
//                 <PlusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               )}
//             </span>
//           </Disclosure.Button>
//         </div>

//         {/* Answer */}
//         <Disclosure.Panel as="div" className="flex flex-col px-16 mt-8">
//           <div className="flex flex-col mt-4 space-y-4">
//             <RegistrationRadio
//               options={agesData}
//               selectedValue={selectedAge}
//               setSelectedValue={setSelectedAge}
//               showOptionalInput={true}
//               id={7}
//             />
//           </div>
//         </Disclosure.Panel>
//         {errors.age && (
//           <p className="text-sm text-red-500/90 mt-1">{errors.age}</p>
//         )}
//       </Fragment>
//     )}
//   </Disclosure>

//   <hr className="h-px bg-primary/20 border-0 my-11"></hr>
// </section>

// {/* 08 */}
// <section className="text-center justify-center flex flex-col gap-2 max-w-7xl mx-auto px-6 lg:px-4 xl:px-0">
//   <Disclosure defaultOpen={false} as="div">
//     {({ open }) => (
//       <Fragment>
//         {/* Question */}
//         <div className="flex flex-row items-center gap-5 pr-6">
//           <div className="flex flex-row items-center gap-5 flex-1">
//             <div className="uppercase text-base font-bold text-primary bg-primary/20 ring-1 ring-primary/30 px-4 rounded-full py-1.5">
//               8
//             </div>
//             <div className="leading-tight text-2xl font-bold text-black">
//               <h1>通訊地址</h1>
//             </div>
//           </div>
//           <Disclosure.Button>
//             {/* Foldable */}
//             <span className="flex h-7 items-center">
//               {open ? (
//                 <MinusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               ) : (
//                 <PlusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               )}
//             </span>
//           </Disclosure.Button>
//         </div>

//         {/* Answer */}
//         <Disclosure.Panel as="div" className="flex flex-col px-16 mt-8">
//           <div className="flex flex-col mt-4 space-y-4"></div>
//         </Disclosure.Panel>
//         {errors.age && (
//           <p className="text-sm text-red-500/90 mt-1">{errors.age}</p>
//         )}
//       </Fragment>
//     )}
//   </Disclosure>

//   <hr className="h-px bg-primary/20 border-0 my-11"></hr>
// </section>

// {/* 09 */}
// <section className="text-center justify-center flex flex-col gap-2 max-w-7xl mx-auto px-6 lg:px-4 xl:px-0">
//   <Disclosure defaultOpen={false} as="div">
//     {({ open }) => (
//       <Fragment>
//         {/* Question */}
//         <div className="flex flex-row items-center gap-5 pr-6">
//           <div className="flex flex-row items-center gap-5 flex-1">
//             <div className="uppercase text-base font-bold text-primary bg-primary/20 ring-1 ring-primary/30 px-4 rounded-full py-1.5">
//               9
//             </div>
//             <div className="leading-tight text-2xl font-bold text-black">
//               <h1>開始成爲照顧者的時間（月份/年份）</h1>
//             </div>
//           </div>
//           <Disclosure.Button>
//             {/* Foldable */}
//             <span className="flex h-7 items-center">
//               {open ? (
//                 <MinusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               ) : (
//                 <PlusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               )}
//             </span>
//           </Disclosure.Button>
//         </div>

//         {/* Answer */}
//         <Disclosure.Panel as="div" className="flex flex-col px-16 mt-8">
//           <div className="flex flex-col mt-4 space-y-4"></div>
//         </Disclosure.Panel>
//         {errors.age && (
//           <p className="text-sm text-red-500/90 mt-1">{errors.age}</p>
//         )}
//       </Fragment>
//     )}
//   </Disclosure>

//   <hr className="h-px bg-primary/20 border-0 my-11"></hr>
// </section>

// {/* 10 */}
// <section className="text-center justify-center flex flex-col gap-2 max-w-7xl mx-auto px-6 lg:px-4 xl:px-0">
//   <Disclosure defaultOpen={false} as="div">
//     {({ open }) => (
//       <Fragment>
//         {/* Question */}
//         <div className="flex flex-row items-center gap-5 pr-6">
//           <div className="flex flex-row items-center gap-5 flex-1">
//             <div className="uppercase text-base font-bold text-primary bg-primary/20 ring-1 ring-primary/30 px-4 rounded-full py-1.5">
//               10
//             </div>
//             <div className="leading-tight text-2xl font-bold text-black">
//               <h1>你的現時就業狀況?</h1>
//             </div>
//           </div>
//           <Disclosure.Button>
//             {/* Foldable */}
//             <span className="flex h-7 items-center">
//               {open ? (
//                 <MinusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               ) : (
//                 <PlusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               )}
//             </span>
//           </Disclosure.Button>
//         </div>

//         {/* Answer */}
//         <Disclosure.Panel as="div" className="flex flex-col px-16 mt-8">
//           <div className="flex flex-col mt-4 space-y-4">
//             <RegistrationRadio
//               options={employmentStatusData}
//               selectedValue={selectedEmployment}
//               setSelectedValue={setSelectedEmployment}
//               showOptionalInput={true}
//               id={10}
//             />
//           </div>
//         </Disclosure.Panel>
//         {errors.age && (
//           <p className="text-sm text-red-500/90 mt-1">{errors.age}</p>
//         )}
//       </Fragment>
//     )}
//   </Disclosure>

//   <hr className="h-px bg-primary/20 border-0 my-11"></hr>
// </section>

// {/* 11 */}
// <section className="text-center justify-center flex flex-col gap-2 max-w-7xl mx-auto px-6 lg:px-4 xl:px-0">
//   <Disclosure defaultOpen={false} as="div">
//     {({ open }) => (
//       <Fragment>
//         {/* Question */}
//         <div className="flex flex-row items-center gap-5 pr-6">
//           <div className="flex flex-row items-center gap-5 flex-1">
//             <div className="uppercase text-base font-bold text-primary bg-primary/20 ring-1 ring-primary/30 px-4 rounded-full py-1.5">
//               11
//             </div>
//             <div className="leading-tight text-2xl font-bold text-black">
//               <h1>您所從事的行業：（如選擇全職或兼職）</h1>
//             </div>
//           </div>
//           <Disclosure.Button>
//             {/* Foldable */}
//             <span className="flex h-7 items-center">
//               {open ? (
//                 <MinusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               ) : (
//                 <PlusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               )}
//             </span>
//           </Disclosure.Button>
//         </div>

//         {/* Answer */}
//         <Disclosure.Panel as="div" className="flex flex-col px-16 mt-8">
//           <div className="flex flex-col mt-4 space-y-4">
//             <RegistrationRadio
//               options={industryData}
//               selectedValue={selectedIndustry}
//               setSelectedValue={setSelectedIndustry}
//               showOptionalInput={true}
//               id={11}
//             />
//           </div>
//         </Disclosure.Panel>
//         {errors.industry && (
//           <p className="text-sm text-red-500/90 mt-1">
//             {errors.industry}
//           </p>
//         )}
//       </Fragment>
//     )}
//   </Disclosure>

//   <hr className="h-px bg-primary/20 border-0 my-11"></hr>
// </section>

// {/* 12 */}
// <section className="text-center justify-center flex flex-col gap-2 max-w-7xl mx-auto px-6 lg:px-4 xl:px-0">
//   <Disclosure defaultOpen={false} as="div">
//     {({ open }) => (
//       <Fragment>
//         {/* Question */}
//         <div className="flex flex-row items-center gap-5 pr-6">
//           <div className="flex flex-row items-center gap-5 flex-1">
//             <div className="uppercase text-base font-bold text-primary bg-primary/20 ring-1 ring-primary/30 px-4 rounded-full py-1.5">
//               12
//             </div>
//             <div className="leading-tight text-2xl font-bold text-black">
//               <h1>你的職業是：</h1>
//             </div>
//           </div>
//           <Disclosure.Button>
//             {/* Foldable */}
//             <span className="flex h-7 items-center">
//               {open ? (
//                 <MinusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               ) : (
//                 <PlusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               )}
//             </span>
//           </Disclosure.Button>
//         </div>

//         {/* Answer */}
//         <Disclosure.Panel as="div" className="flex flex-col px-16 mt-8">
//           <div className="flex flex-col mt-4 space-y-4">
//             <RegistrationRadio
//               options={jobData}
//               selectedValue={selectedJob}
//               setSelectedValue={setSelectedJob}
//               showOptionalInput={true}
//               id={12}
//             />
//           </div>
//         </Disclosure.Panel>
//         {errors.job && (
//           <p className="text-sm text-red-500/90 mt-1">{errors.job}</p>
//         )}
//       </Fragment>
//     )}
//   </Disclosure>

//   <hr className="h-px bg-primary/20 border-0 my-11"></hr>
// </section>

// {/* 13 */}
// <section className="text-center justify-center flex flex-col gap-2 max-w-7xl mx-auto px-6 lg:px-4 xl:px-0">
//   <Disclosure defaultOpen={false} as="div">
//     {({ open }) => (
//       <Fragment>
//         {/* Question */}
//         <div className="flex flex-row items-center gap-5 pr-6">
//           <div className="flex flex-row items-center gap-5 flex-1">
//             <div className="uppercase text-base font-bold text-primary bg-primary/20 ring-1 ring-primary/30 px-4 rounded-full py-1.5">
//               13
//             </div>
//             <div className="leading-tight text-2xl font-bold text-black">
//               <h1>你的婚姻狀況：</h1>
//             </div>
//           </div>
//           <Disclosure.Button>
//             {/* Foldable */}
//             <span className="flex h-7 items-center">
//               {open ? (
//                 <MinusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               ) : (
//                 <PlusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               )}
//             </span>
//           </Disclosure.Button>
//         </div>

//         {/* Answer */}
//         <Disclosure.Panel as="div" className="flex flex-col px-16 mt-8">
//           <div className="flex flex-col mt-4 space-y-4">
//             <RegistrationRadio
//               options={martialStatusData}
//               selectedValue={selectedMartial}
//               setSelectedValue={setSelectedMartial}
//               showOptionalInput={false}
//               id={13}
//             />
//           </div>
//         </Disclosure.Panel>
//         {errors.martialStatus && (
//           <p className="text-sm text-red-500/90 mt-1">
//             {errors.martialStatus}
//           </p>
//         )}
//       </Fragment>
//     )}
//   </Disclosure>

//   <hr className="h-px bg-primary/20 border-0 my-11"></hr>
// </section>

// {/* 14 */}
// <section className="text-center justify-center flex flex-col gap-2 max-w-7xl mx-auto px-6 lg:px-4 xl:px-0">
//   <Disclosure defaultOpen={false} as="div">
//     {({ open }) => (
//       <Fragment>
//         {/* Question */}
//         <div className="flex flex-row items-center gap-5 pr-6">
//           <div className="flex flex-row items-center gap-5 flex-1">
//             <div className="uppercase text-base font-bold text-primary bg-primary/20 ring-1 ring-primary/30 px-4 rounded-full py-1.5">
//               14
//             </div>
//             <div className="leading-tight text-2xl font-bold text-black">
//               <h1>
//                 從你的角度，「照顧者」的角色對你的生活帶來什麼挑戰？
//               </h1>
//             </div>
//           </div>
//           <Disclosure.Button>
//             {/* Foldable */}
//             <span className="flex h-7 items-center">
//               {open ? (
//                 <MinusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               ) : (
//                 <PlusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               )}
//             </span>
//           </Disclosure.Button>
//         </div>

//         {/* Answer */}
//         <Disclosure.Panel as="div" className="flex flex-col px-16 mt-8">
//           {/*  Details */}
//           <div className="text-left mt-1">
//             <div className="w-8/12">
//               <textarea
//                 type="text"
//                 id="challenges"
//                 name="challenges"
//                 rows={7}
//                 {...register("challenges.challenges")}
//                 className="block w-full rounded-xl text-base border-0 py-2.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:leading-6"
//                 placeholder="Type your answer here"
//               />
//             </div>
//             {errors.challenges && (
//               <p className="text-sm text-red-500/90 mt-1">
//                 {errors.challenges}
//               </p>
//             )}
//           </div>
//         </Disclosure.Panel>
//         {errors.job && (
//           <p className="text-sm text-red-500/90 mt-1">{errors.job}</p>
//         )}
//       </Fragment>
//     )}
//   </Disclosure>

//   <hr className="h-px bg-primary/20 border-0 my-11"></hr>
// </section>

// {/* 15 */}
// <section className="text-center justify-center flex flex-col gap-2 max-w-7xl mx-auto px-6 lg:px-4 xl:px-0">
//   <Disclosure defaultOpen={false} as="div">
//     {({ open }) => (
//       <Fragment>
//         {/* Question */}
//         <div className="flex flex-row items-center gap-5 pr-6">
//           <div className="flex flex-row items-center gap-5 flex-1">
//             <div className="uppercase text-base font-bold text-primary bg-primary/20 ring-1 ring-primary/30 px-4 rounded-full py-1.5">
//               15
//             </div>
//             <div className="leading-tight text-2xl font-bold text-black">
//               <h1>
//                 你（照顧者）每周七天有多少個小時的私人時間 (ME Time)?
//                 (註：不包括睡覺時間)
//               </h1>
//             </div>
//           </div>
//           <Disclosure.Button>
//             {/* Foldable */}
//             <span className="flex h-7 items-center">
//               {open ? (
//                 <MinusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               ) : (
//                 <PlusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               )}
//             </span>
//           </Disclosure.Button>
//         </div>

//         {/* Answer */}
//         <Disclosure.Panel as="div" className="flex flex-col px-16 mt-8">
//           <div className="flex flex-col mt-4 space-y-4">
//             <RegistrationRadio
//               options={meTimeData}
//               selectedValue={selectedMeTime}
//               setSelectedValue={setSelectedMeTime}
//               showOptionalInput={true}
//               id={15}
//             />
//           </div>
//         </Disclosure.Panel>
//         {errors.meTime && (
//           <p className="text-sm text-red-500/90 mt-1">
//             {errors.meTime}
//           </p>
//         )}
//       </Fragment>
//     )}
//   </Disclosure>

//   <hr className="h-px bg-primary/20 border-0 my-11"></hr>
// </section>

// {/* 16 */}
// <section className="text-center justify-center flex flex-col gap-2 max-w-7xl mx-auto px-6 lg:px-4 xl:px-0">
//   <Disclosure defaultOpen={false} as="div">
//     {({ open }) => (
//       <Fragment>
//         {/* Question */}
//         <div className="flex flex-row items-center gap-5 pr-6">
//           <div className="flex flex-row items-center gap-5 flex-1">
//             <div className="uppercase text-base font-bold text-primary bg-primary/20 ring-1 ring-primary/30 px-4 rounded-full py-1.5">
//               16
//             </div>
//             <div className="leading-tight text-2xl font-bold text-black">
//               <h1>
//                 你（照顧者）會利用私人時間(ME Time)
//                 進行甚麼活動？(註：不包括睡覺時間) (可選擇多於一項)
//               </h1>
//             </div>
//           </div>
//           <Disclosure.Button>
//             {/* Foldable */}
//             <span className="flex h-7 items-center">
//               {open ? (
//                 <MinusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               ) : (
//                 <PlusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               )}
//             </span>
//           </Disclosure.Button>
//         </div>

//         {/* Answer */}
//         <Disclosure.Panel as="div" className="flex flex-col px-16 mt-8">
//           <div className="flex flex-col mt-4 space-y-4">
//             <RegistrationRadio
//               options={activitiesMeTimeData}
//               selectedValue={selectedMeTimeActivities}
//               setSelectedValue={setSelectedMeTimeActivities}
//               showOptionalInput={true}
//               id={16}
//             />
//           </div>
//         </Disclosure.Panel>
//         {errors.meTimeActivities && (
//           <p className="text-sm text-red-500/90 mt-1">
//             {errors.meTimeActivities}
//           </p>
//         )}
//       </Fragment>
//     )}
//   </Disclosure>

//   <hr className="h-px bg-primary/20 border-0 my-11"></hr>
// </section>

// {/* 17 */}
// <section className="text-center justify-center flex flex-col gap-2 max-w-7xl mx-auto px-6 lg:px-4 xl:px-0">
//   <Disclosure defaultOpen={false} as="div">
//     {({ open }) => (
//       <Fragment>
//         {/* Question */}
//         <div className="flex flex-row items-center gap-5 pr-6">
//           <div className="flex flex-row items-center gap-5 flex-1">
//             <div className="uppercase text-base font-bold text-primary bg-primary/20 ring-1 ring-primary/30 px-4 rounded-full py-1.5">
//               17
//             </div>
//             <div className="leading-tight text-2xl font-bold text-black">
//               <h1>你熟悉的技能：(如適用)</h1>
//             </div>
//           </div>
//           <Disclosure.Button>
//             {/* Foldable */}
//             <span className="flex h-7 items-center">
//               {open ? (
//                 <MinusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               ) : (
//                 <PlusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               )}
//             </span>
//           </Disclosure.Button>
//         </div>

//         {/* Answer */}
//         <Disclosure.Panel as="div" className="flex flex-col px-16 mt-8">
//           {/*  Details */}
//           <div className="text-left mt-1">
//             <div className="w-8/12">
//               <textarea
//                 type="text"
//                 id="skills"
//                 name="skills"
//                 rows={7}
//                 {...register("skills.skills")}
//                 className="block w-full rounded-xl text-base border-0 py-2.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:leading-6"
//                 placeholder="Type your answer here"
//               />
//             </div>
//             {errors.skills && (
//               <p className="text-sm text-red-500/90 mt-1">
//                 {errors.skills}
//               </p>
//             )}
//           </div>
//         </Disclosure.Panel>
//         {errors.skills && (
//           <p className="text-sm text-red-500/90 mt-1">
//             {errors.skills}
//           </p>
//         )}
//       </Fragment>
//     )}
//   </Disclosure>

//   <hr className="h-px bg-primary/20 border-0 my-11"></hr>
// </section>

// {/* /// Section separator /// */}
// <div className="text-left bg-primary mt-12">
//   <div className="leading-tight text-3xl max-w-7xl font-semibold text-white mx-auto py-4 px-8">
//     <h2>被照顧者資料</h2>
//   </div>
// </div>

// {/* /// Section separator /// */}
// <div className="text-left bg-primary mt-12">
//   <div className="leading-tight text-3xl max-w-7xl font-semibold text-white mx-auto py-4 px-8">
//     <h2>照顧者的其他個人資料</h2>
//   </div>
// </div>

// {/* 19 */}
// <section className="text-center mt-12 justify-center flex flex-col gap-2 max-w-7xl mx-auto px-6 lg:px-4 xl:px-0">
//   <Disclosure defaultOpen={false} as="div">
//     {({ open }) => (
//       <Fragment>
//         {/* Question */}
//         <div className="flex flex-row items-center gap-5 pr-6">
//           <div className="flex flex-row items-center gap-5 flex-1">
//             <div className="uppercase text-base font-bold text-primary bg-primary/20 ring-1 ring-primary/30 px-4 rounded-full py-1.5">
//               19
//             </div>
//             <div className="leading-tight text-2xl font-bold text-black">
//               <h1>獲悉本計劃途徑：(可選多項)</h1>
//             </div>
//           </div>
//           <Disclosure.Button>
//             {/* Foldable */}
//             <span className="flex h-7 items-center">
//               {open ? (
//                 <MinusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               ) : (
//                 <PlusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               )}
//             </span>
//           </Disclosure.Button>
//         </div>
//         {/* Answer */}
//         <Disclosure.Panel as="div" className="flex flex-col px-16 mt-8">
//           <div className="flex flex-col mt-4 space-y-4">
//             <Checkboxes
//               options={howDidYouFindOutData}
//               selectedValue={selectedHearAboutUs}
//               setSelectedValue={setSelectedHearAboutUs}
//               showOptionalInput={false}
//               id={19}
//             />
//           </div>
//         </Disclosure.Panel>
//         {errors.hearAboutUs && (
//           <p className="text-sm text-red-500/90 mt-1">
//             {errors.hearAboutUs}
//           </p>
//         )}
//       </Fragment>
//     )}
//   </Disclosure>

//   <hr className="h-px bg-primary/20 border-0 my-11"></hr>
// </section>

// {/* 20 */}
// <section className="text-center justify-center flex flex-col gap-2 max-w-7xl mx-auto px-6 lg:px-4 xl:px-0">
//   <Disclosure defaultOpen={false} as="div">
//     {({ open }) => (
//       <Fragment>
//         {/* Question */}
//         <div className="flex flex-row items-center gap-5 pr-6">
//           <div className="flex flex-row items-center gap-5 flex-1">
//             <div className="uppercase text-base font-bold text-primary bg-primary/20 ring-1 ring-primary/30 px-4 rounded-full py-1.5">
//               20
//             </div>
//             <div className="leading-tight text-2xl font-bold text-black">
//               <h1>您希望主要通訊聯絡方式：</h1>
//             </div>
//           </div>
//           <Disclosure.Button>
//             {/* Foldable */}
//             <span className="flex h-7 items-center">
//               {open ? (
//                 <MinusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               ) : (
//                 <PlusCircleIcon
//                   className="h-10 w-10 text-primary"
//                   aria-hidden="true"
//                 />
//               )}
//             </span>
//           </Disclosure.Button>
//         </div>

//         {/* Answer */}
//         <Disclosure.Panel as="div" className="flex flex-col px-16 mt-8">
//           <div className="flex flex-col mt-4 space-y-4">
//             <RegistrationRadio
//               options={preferredComChannelData}
//               selectedValue={selectedPreferredCom}
//               setSelectedValue={setSelectedPreferredCom}
//               showOptionalInput={true}
//               id={20}
//             />
//           </div>
//         </Disclosure.Panel>
//         {errors.meTimeActivities && (
//           <p className="text-sm text-red-500/90 mt-1">
//             {errors.meTimeActivities}
//           </p>
//         )}
//       </Fragment>
//     )}
//   </Disclosure>
// </section>
